<template>
  <div
    :class="{hide:!show}"
    class="w-pop"
  >
    <div class="w-pop-img" />
  </div>
</template>
<script>
export default {
  data() {
    return {
      show: false,
    };
  },
  mounted() {
    this.checkOrientation();
    window.addEventListener('resize', this.checkOrientation);
    setInterval(this.checkOrientation, 5000);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkOrientation);
  },
  methods: {
    checkOrientation() {
      const cw = document.documentElement.clientWidth;
      // eslint-disable-next-line no-underscore-dangle
      let _Width = 0;
      // eslint-disable-next-line no-underscore-dangle
      let _Height = 0;
      const sw = window.screen.width;
      const sh = window.screen.height;
      // 2.在某些机型（如华为P9）下出现 srceen.width/height 值交换，所以进行大小值比较判断
      _Width = sw < sh ? sw : sh;
      _Height = sw >= sh ? sw : sh;

      if (cw === _Width) {
        // 竖屏
        this.show = false;
        return;
      }
      if (cw === _Height) {
        // 横屏
        this.show = true;
      }
    },
  },
};
</script>
<style lang="scss">
$radio: 80;
@function tpx($width) {
    @return $width/$radio * 1rem;
}
.w-pop {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 1);
    z-index: 9999;
}
.w-pop-img {
    width: tpx(256);
    height: tpx(215);
    background: url(https://static.gameplus.qq.com/business/gameh5/icon_turnv.png)
        no-repeat;
    background-size: 100% 100%;
    position: absolute;
    left: 50%;
    margin-left: -1.5rem;
    top: 50%;
    margin-top: -1.2rem;
    // animation: rotateA 1.5s ease infinite alternate;
}
@keyframes rotateA {
    0% {
        transform: rotate(0deg);
    }
    30% {
        transform: rotate(0deg);
    }
    70% {
        transform: rotate(-90deg);
    }
    100% {
        transform: rotate(-90deg);
    }
}
</style>
