<template>
  <div class="h5-container">
    <div class="right-wrap">
      <!--头部-->
      <UserInfo
        :user-info="pageData.userInfo"
        @clickTips="ruleDialogShow = !ruleDialogShow"
      />

      <!--签到抽奖-->
      <v-sign-gift
        v-if="signGiftList.length"
        :group-id="groupId"
        :gift-list="signGiftList"
      />

      <!--礼包兑换-->
      <v-card-gift
        v-if="giftList.length"
        :group-id="groupId"
        :gift-list="giftList"
      />

      <!--轮播卡片-->
      <CardSwiper
        v-if="pageData.rankInfo && pageData.userInfo"
        :show="true"
        :rank-info="pageData.rankInfo"
        :user-info="pageData.userInfo"
        outer-container-id="card-swiper"
      />

      <!--banner-->
      <v-banner />

      <!--特权-->
      <Privilege
        :privilege-info="pageData.privilegeInfo"
        :user-info="pageData.userInfo"
      />

      <v-rule
        v-model="ruleDialogShow"
        :rule="pageData.upRule"
      />

      <div class="page-btn-jump">
        <v-btn-jump />
      </div>
    </div>
    <VTip />
  </div>
</template>
<script>
import './source/fixable';
import VRule from '@/page/embed-page/download-in-app/component/rule.vue';
import VBanner from '@/page/embed-page/download-in-app/component/banner.vue';
import VCardGift from '@/page/embed-page/download-in-app/component/card-gift.vue';
import VBtnJump from '@/page/embed-page/download-in-app/component/btn-jump/index.vue';
import VSignGift from '@/page/embed-page/download-in-app/component/sign-gift.vue';
import CardSwiper from './component/card-swiper.vue';
import Privilege from './component/privilege.vue';
import UserInfo from './component/userinfo.vue';
import VTip from './component/v-tip.vue';
import './component/swiper/index';
import './component/swiper/swiper-3.4.2.min.css';
import {
  getGiftList, getUserInfo, getSignGifts, getGroupId, logEvent,
} from './server';

// 子项目
export default {
  components: {
    VSignGift,
    VRule,
    VBtnJump,
    VCardGift,
    VBanner,
    CardSwiper,
    Privilege,
    UserInfo,
    VTip,
  },
  data() {
    return {
      ruleDialogShow: false,
      groupId: 0,
      pageData: {},
      giftList: [],
      signGiftList: [],
    };
  },
  async created() {
    this.pageData = await getUserInfo();
    logEvent({ eventType: 1, extendId: 1, operId: '1901000010101' });
    [this.groupId, this.signGiftList, this.giftList] = await Promise.all([
      getGroupId(),
      getSignGifts(),
      getGiftList(),
    ]);
  },
};
</script>
<style lang="scss">
$radio: 110;
@function tpx($width) {
  @return 1.467 * $width/$radio * 1rem;
}

@import "./source/index";
@import "./source/index-v";
</style>
